import React from "react";

export const ecoCenter = {
  uk: {
    mission1: 'Місія ',
    mission2:
      '«ЕКО-ЦЕНТРУ СТІНА» слугувати майданчиком співпраці різномантіних суб’єктів, що зацікавлені у практичній реалізації ідей сталого розвитку, обміні та збереженні сільської культури, а також реалізації сільських ініціатив.',
    download: 'Завантажити  презентацію',
    contact1: '+38-097-6402756 (Ярослав)',
    contact2: '+38-098-9315492 (Надія)',
    contact3: 'eco.center.stina@gmail.com',
    address: 'с.Стіна, вул. Кельбаса, 103, 203',
    founded:
      'Заснований 2017 року, сьогодні «ЕКО-ЦЕНТР СТІНА» розвивається ГО «Центр розвитку Пангея Ультіма» та ГО «Наше Поділля»',
    listHead1: '«ЕКО-ЦЕНТР СТІНА» ',
    listHead2: 'працює для:',
    listItem1: 'Учасників освітніх, культурних, молодіжних заходів і таборів;',
    listItem2: 'Місцевих жителів та молоді;',
    listItem3:
      'Партнерів, що реалізують громадянські, освітні та екологічні ініціативи;',
    listItem4: 'Туристичних груп та гостей Стіни.',
    buttonsHead: 'ЩО ВИ МОЖЕТЕ У НАС ЗАМОВИТИ',
    button0: 'Занурення у автентику',
    button1: 'Кемпінг і літній хостел',
    button2: 'Всесезонний хостел',
    button3: 'Культурні та мистецькі програми',
    button4: 'Навчальні програми',
    teamHead: 'Наша команда',
    participant1Name: 'Анастасія Суботіна',
    participant1Role: 'Місцева активістка',
    participant2Name: 'Ярослав Геращенко',
    participant2Role: 'Голова ГО «ЦР Пангея Ультіма»',
    participant3Name: 'Надія Ярушняк',
    participant3Role: 'Екскурсоводка',
    participant4Name: 'Михайло Осипенко',
    participant4Role: 'Місцевий активіст',
    participant5Name: 'Юрій Степанець',
    participant5Role: 'Голова ГО «Наше Поділля»',
    Slide0:
      '«ЕКО-ЦЕНТР СТІНА» створить найсприятливіші умови для повного занурення у багату сільську культуру південного Поділля. Усіх, хто зупиняється, навчається чи відпочиває у нас, заворожує особлива атмосфера, котра наче застигла у часі. А спілкування із місцевим населенням приносить задоволення від прикладу як люди вміють дбати про збереження власних традицій.',
    Slide1:[
      "«ЕКО-ЦЕНТР СТІНА» має можливість прийняти у форматі табору близько сорока гостей. На території є літні душі, туалети, доступ до електрики, кухні, інтернету, а також семінарна кімната. В будиночку маємо до дев'яти ліжко-місць. Також паралельно з можливістю приготування їжі самотужки на вогнищі чи кухні ми можемо забезпечити гостей традиційною кухнею від місцевих господинь.",
      <br/>,
      'Вартості ',
      <a href="https://stina.pangeya.org.ua/selo-stina/category/accommodation-placements/articles/eco-center-stina" target="blank">ТУТ</a>
    ],
    Slide2: [
      '«ЕКО-ЦЕНТР СТІНА» Еко-Центр Стіна пропонує для гостей Стіни проживання у хостелі з усіма зручностями та сучасною кухнею. Хостел знаходиться неподалік кемпінгу та може прийняти 8 чоловік на ліжко-місця, а також має простір для проведення заходів. У хостелі працює опалення, тому ми можемо приймати гостей увесь рік. Ми також можемо допомогти із замовленням харчування від місцевих господинь.',
      <br/>,
      'Вартості ',
      <a href="https://stina.pangeya.org.ua/selo-stina/category/accommodation-placements/articles/eco-center-hostel" target="blank">ТУТ</a>
    ],
    Slide3:
      'У «ЕКО-ЦЕНТРІ СТІНА» ви можете доторкнутись до традицій місцевого краю, зануритись у атмосферу автентичності та дійства. Це місце надихає на містичні розмови, креативне самопізнання та споглядання нічного неба. «ЕКО-ЦЕНТР СТІНА» це місце, куди прямують митці, музики, художники та шукачі унікального досвіду. Запрошуємо вас надихатись та насолоджуватись справжнім спілкуванням у Стіні!',
    Slide4:
      '«ЕКО-ЦЕНТР СТІНА» це місце реалізації освітніх заходів неформальної, досвідної форми та освіти на природі. Ми маємо практику проведення міжнародних та молодіжних проєктів, що засновані на обміні культурою, працюємо із волонтерськими таборами. Тут ви знайдете всі умови для успішного розкриття своїх учасників, їх відпочинку та повного занурення у тему заходу через соціальну близькість та єднання із природою Стіни.',
  },
  en: {
    mission1: 'The mission ',
    mission2:
      'of "ECO-CENTER STINA" to serve as a platform for cooperation of various actors interested in the practical implementation of ideas of sustainable development, exchange and preservation of rural culture, as well as the implementation of rural initiatives.',
    download: 'Download the presentation',
    contact1: '+38-097-6402756 (Iaroslav)',
    contact2: '+38-098-9315492 (Nadiya)',
    contact3: 'eco.center.stina@gmail.com',
    address: 'Stina village, Kelbas str., 103, 203',
    founded:
      'Founded in 2017 today "ECO-CENTER STINA" is developed by NGO "Development center Pangeya Ultima" and NGO "Nashe Podillia"',
    listHead1: '"ECO-CENTER STINA" ',
    listHead2: 'works for:',
    listItem1: 'Participants in educational, cultural, youth events and camps;',
    listItem2: 'Local residents and youth;',
    listItem3:
      'Partners implementing civic, educational and environmental initiatives;',
    listItem4: 'Tourist groups and guests of Stina.',
    buttonsHead: 'WHAT YOU CAN ORDER HERE',
    button0: 'Immersion in authenticity',
    button1: 'Camping and summer hostel',
    button2: 'All seasons hostel',
    button3: 'Cultural and artistic programs',
    button4: 'Educational programs',
    teamHead: 'Our team',
    participant1Name: 'Anastasiya Subotina',
    participant1Role: 'Local activist',
    participant2Name: 'Iaroslav Gerashchenko',
    participant2Role: 'Head of NGO "DC Pangeya Ultima"',
    participant3Name: 'Nadiya Yarushniak',
    participant3Role: 'Local tour guide',
    participant4Name: 'Mykhailo Osypenko',
    participant4Role: 'Local activist',
    participant5Name: 'Yuriy Stepanets',
    participant5Role: 'Head of NGO "Nashe Podillia"',
    Slide0:
      '"ECO-CENTER STINA" will create the most favorable conditions for full immersion in the rich rural culture of southern Podillya. Everyone who stops, studies or rests with us is fascinated by the special atmosphere, which seems to be frozen in time. And communication with the local population brings pleasure from the example of how people know how to take care of preserving their own traditions.',
    Slide1:[
      '"ECO-CENTER STINA" has the capacity to host around forty guests in the format of a camp. The territory has summer showers, toilets, access to electricity, kitchen, Internet, as well as a seminar hall. We have up to nine beds in the house. Also, in parallel with the possibility of cooking by yourself on the hearth or in the kitchen, we can provide guests with traditional cuisine from local hostesses.',
      <br/>,
      'Prices are ',
      <a href="https://stina.pangeya.org.ua/selo-stina/category/accommodation-placements/articles/eco-center-stina?lang=en" target="blank">HERE</a>
    ],
    Slide2: [
      '"ECO-CENTER STINA" offers guests of Stina accommodation in a hostel with all amenities and a modern kitchen. The hostel is located near the campsite and can accommodate 8 people in beds, and also has space for events. The hostel is heated, so we can accept guests all year round. We can also help with ordering food from local hostesses.',
      <br/>,
      'Prices are ',
      <a href="https://stina.pangeya.org.ua/selo-stina/category/accommodation-placements/articles/eco-center-hostel?lang=en" target="blank">HERE</a>
    ],
    Slide3:
      'In "ECO-CENTER STINA" you can touch the traditions of the local area, immerse yourself in the atmosphere of authenticity and action. This place inspires mystical conversations, creative self-exploration and contemplation of the night sky. Artisans, musicians, painters and seekers of unique experience head to "ECO-CENTER STINA". We invite you to be inspired and enjoy real communication in Stina!',
    Slide4:
      '"ECO-CENTER STINA" is a place for non-formal, experiential and outdoor educational activities. We have the practice of conducting international youth projects based on cultural exchange, we work with volunteer camps. Here you will find all the conditions for the successful opening of your participants, their recreation and complete immersion in the theme of the event through social closeness and unity with the nature of Stina.',
  },
};
